import { useSelector } from 'react-redux';
import s from './DevItem.module.scss'
import { useParams } from 'react-router-dom'
import { AudioPlayerComponent } from '../../components/AudioPlayer/AudioPlayer';
import { LinkBox } from '../../components/LinkBox/LinkBox';
import { useTranslation } from 'react-i18next';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';
import { removeNumbers, stringToNumber } from '../../helper';

export const DevItem = () => {
    const { devs } = useSelector(state => state.devs)
    const { t } = useTranslation()
    const { id } = useParams()

    const getPersonData = (id) => {
        const dev = devs?.find((dev, index) => dev.development.pk === id);
        return dev ? dev : null
    }
    const devData = getPersonData(Number(id))
    
    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('development')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar
                    mainPage={t('mainLink')}
                    secondLevel={t('development')}
                    secondLink={'/development'}
                    thirdLevel={devData?.name} />
                <div className={s.person_title}>
                    {devData?.name}
                </div>
                <div className={s.person_information}>
                    <div className={s.left_block}>
                        <img src={devData?.development.images[1]} alt="" />
                        <AudioPlayerComponent src={devData?.audio} data={devs} link={'/development'} />
                    </div>
                    <div>
                        <div
                            dangerouslySetInnerHTML={{ __html: devData?.about }}
                        />
                        {devData?.development.organization &&
                            (<div className={s.link_to_organization}>
                                <div className={s.link_title}>
                                    {t('developer')}:
                                </div>
                                <LinkBox
                                    title={removeNumbers(devData.development.organization.key_name_translation)}
                                    path={`/organizations/${stringToNumber(devData.development.organization.key_name)}`} />
                            </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}