import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import s from '../OrgItem.module.scss'
import { useTranslation } from "react-i18next";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { stringToNumber } from "../../../helper"
import { LinkBox } from '../../../components/LinkBox/LinkBox'

export const ResearchAreas = () => {
    const { organizations } = useSelector(state => state.organizations);
    const { id } = useParams()
    const { t } = useTranslation()
    const getPlaceData = (id) => {
        const organization = organizations?.find(org => stringToNumber(org.name) === id);
        return organization ? organization : null
    }
    const organizationData = getPlaceData(Number(id))
    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('organization')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar
                    mainPage={t('mainLink')}
                    secondLevel={t('organization')}
                    secondLink={'/organizations'}
                    thirdLevel={organizationData?.name}
                    thirdLink={`/organizations/${id}`}
                    fourthLevel={t('branches')} />
                <div className={s.link_title}>
                    {t('branches')}
                </div>
                <div className={s.areas}>
                    {organizationData?.research_areas.map((area, index) => (
                        <div className={s.area} key={index}>{area}</div>
                    ))}
                </div>
                <div className={s.links}>
                    <LinkBox
                        title={t('achievements')}
                        path={`/organizations/${id}/achievements`} />
                    <LinkBox
                        title={t('contacts')}
                        path={`/organizations/${id}/contacts`} />
                </div>
            </div>
        </div>
    )
}