import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import s from './SearchResults.module.scss';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';
import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import { ReactComponent as Search } from '../../assets/search.svg'
import { ReactComponent as Cross } from '../../assets/cross.svg'
import { stringToNumber } from '../../helper';

// Функция для получения параметра запроса из URL
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const SearchResults = () => {
    const query = useQuery().get('query') || ''
    const [searchInput, setSearchInput] = useState(query)
    const [selectedCategory, setSelectedCategory] = useState('all')
    const lowerCaseQuery = query.toLowerCase()

    // Получаем данные из Redux store
    const { events } = useSelector(state => state.events);
    const { persons } = useSelector(state => state.persons);
    const { places } = useSelector(state => state.places);
    const { devs } = useSelector(state => state.devs);
    const { organizations } = useSelector(state => state.organizations);

    const navigate = useNavigate()
    const { t } = useTranslation()
    // Функция для фильтрации данных
    const filterItems = (items) =>
        items?.filter(item =>
            item.name?.toLowerCase().includes(lowerCaseQuery) ||
            item.about?.toLowerCase().includes(lowerCaseQuery)
        );

    const filteredResults = {
        person: filterItems(persons),
        places: filterItems(places),
        development: filterItems(devs),
        organization: filterItems(organizations),
        date: filterItems(events),
    }

    const displayedResults = selectedCategory === 'all'
        ? filteredResults
        : { [selectedCategory]: filteredResults[selectedCategory] }

    const handleInputChange = (e) => {
        setSearchInput(e.target.value)
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            navigate(`?query=${encodeURIComponent(searchInput)}`)
        }
    }

    const handleCategoryChange = (category) => {
        setSelectedCategory(category)
    }
    const createLinkToPage = (category, item) => {
        switch (category) {
            case 'development':
                return `/development/${item.development.pk}`
            case 'organization':
                return `/organizations/${stringToNumber(item.name)}`
            case 'person':
                return `/history/persons/${item.pk}`
            case 'places':
                return `/history/places/${stringToNumber(item.name)}`
            case 'date':
                return `/history/date?query=${encodeURIComponent(item.event.start_date)}`
        }
    }
    return (
        <div className={s.results_container}>
            <div className={s.high_title}>
                {t('searchResutls')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar
                    mainPage={t('mainLink')}
                    secondLevel={t('searchResutls')} />
                <div className={s.input_container}>
                    <input
                        type="text"
                        value={searchInput}
                        onChange={handleInputChange}
                        onKeyDown={handleSearch}
                        placeholder={t('search')}
                    />
                    <Cross onClick={() => setSearchInput('')} className={s.cross} />
                    <Search onClick={handleSearch} />
                </div>
                <div className={s.category_buttons}>
                    {['all', 'date', 'person', 'places', 'organization', 'development' ].map(category => (
                        <button
                            key={category}
                            onClick={() => handleCategoryChange(category)}
                            className={selectedCategory === category ? s.active_button : ''}
                        >
                            {t(category)}
                        </button>
                    ))}
                </div>
                {Object.entries(displayedResults).map(([category, items]) => (
                    items?.length > 0 && (
                        <div key={category} className={s.category_section}>
                            {items?.map(item => (
                                <div className={s.search_item} key={item.id}>
                                    <div className={s.item_title}>
                                        {item.name}
                                    </div>
                                    {item.about && <div className={s.item_text}
                                        dangerouslySetInnerHTML={{ __html: item.about }}
                                    />}
                                    <Link className={s.link} to={createLinkToPage(category, item)}>
                                        {t('link')}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )
                ))}
                {Object.values(filteredResults).every(items => items?.length === 0) && (
                    <p>No results found for "{query}".</p>
                )}
            </div>
        </div>
    )
}