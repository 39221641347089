import s from './SearchComponent.module.scss'
import { ReactComponent as Search } from '../../assets/search.svg'
import { ReactComponent as Cross } from '../../assets/cross.svg'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const SearchComponent = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const navigate = useNavigate()

    const { events } = useSelector(state => state.events)
    const { persons } = useSelector(state => state.persons)
    const { places } = useSelector(state => state.places)
    const { devs } = useSelector(state => state.devs)
    const { organizations } = useSelector(state => state.organizations)
    const searchBodyRef = useRef(null)
    const { t } = useTranslation()

    const filterItems = (items) =>
        items?.filter(item =>
        (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.about?.toLowerCase().includes(searchQuery.toLowerCase()))
        )

    const filteredResults = {
        persons: filterItems(persons),
        places: filterItems(places),
        devs: filterItems(devs),
        organizations: filterItems(organizations),
        events: filterItems(events),
    }
    const closeOptionBody = (event) => {
        if (searchBodyRef.current && !searchBodyRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click', closeOptionBody)
        return () => {
            document.removeEventListener('click', closeOptionBody)
        }
    }, [])

    const goToSearchResults = () => {
        if (searchQuery) {
            navigate(`/search-results?query=${encodeURIComponent(searchQuery)}`)
            setSearchQuery('')
            setIsOpen(false)

        }
    }

    // Обработчик нажатия клавиши Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            goToSearchResults();
        }
    }

    return (
        <div ref={searchBodyRef} className={s.search_container}>
            <Search onClick={() => setIsOpen(true)} />
            <div className={`${s.input_container} ${isOpen ? s.active : ''}`}>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={t('search')}
                />
                <Cross onClick={() => setSearchQuery('')} className={s.cross}/>
                <Search onClick={goToSearchResults}/>
                <div onClick={() => setIsOpen(false)} className={s.cancel}>
                    {t('cancel')}
                </div>
            </div>
            {isOpen && searchQuery && (
                <div className={s.dropdown}>
                    {Object.entries(filteredResults).map(([category, items]) => (
                        items.length > 0 &&
                        items.slice(0, 3).map(item => (
                            <div key={item.id}>{item.name}</div>
                        ))
                    ))}
                </div>
            )}
        </div>
    )
}