import { useSelector } from 'react-redux';
import s from './Person.module.scss'
import { useParams } from 'react-router-dom'
import { AudioPlayerComponent } from '../../components/AudioPlayer/AudioPlayer';
import { useTranslation } from 'react-i18next';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';

export const Person = () => {
    const { persons } = useSelector(state => state.persons);
    const { id } = useParams()
    const { t } = useTranslation()

    const getPersonData = (id) => {
        const person = persons?.find((point, index) => point.pk === id);
        return person ? person : null
    }
    const personData = getPersonData(Number(id))
    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('person')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar
                    mainPage={t('mainLink')}
                    secondLevel={t('history')}
                    secondLink={'/history'}
                    thirdLevel={t('person')} 
                    thirdLink={'/history/persons'}
                    fourthLevel={personData?.name}/>
                <div className={s.person_title}>
                    {personData?.name}
                </div>
                <div className={s.person_information}>
                    <div className={s.left_block}>
                        <img src={personData?.person.images[1]} alt="" />
                        <AudioPlayerComponent src={personData?.audio} data={persons} link={'/history/persons'} />
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: personData?.about }}
                    />
                </div>
            </div>
        </div>
    )
}