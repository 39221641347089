import { Link } from 'react-router-dom'
import s from './CardItem.module.scss'

export const CardItem = ({link, image, text}) => {
    return (
        <Link to={`/${link}/`} className={s.card_item}>
            <img src={image} alt="" />
            <div className={s.text}>
                <span>
                   {text}
                </span>
            </div>
        </Link>
    )
}
