import { useEffect, useState } from 'react'
import { Calendar } from '../../components/Calendar/Calendar'
import s from './DatePage.module.scss'
import { format, isAfter,  parse } from 'date-fns';
import be from 'date-fns/locale/be'
import enUS from 'date-fns/locale/en-US'
import ru from 'date-fns/locale/ru'
import { useSelector } from 'react-redux'
import { NewsItem } from '../../components/NewsItem/NewsItem';
import { useTranslation } from 'react-i18next';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar';
import { ReactComponent as Loading} from '../../assets/loading.svg'
import { ReactComponent as CalIcon} from '../../assets/calendar.svg'
import { ReactComponent as Cross} from '../../assets/cross.svg'
import { useLocation } from 'react-router-dom';

const locales = { en: enUS, be, ru }

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
export const DatePage = () => {
	 const query = useQuery().get('query')
	const today = new Date()
	const { t } = useTranslation()
	const [selectedDate, setSelectedDate] = useState(query || today)
	const [isToday, setIstoday] = useState(true)	
	const { events, language, status } = useSelector(state => state.events)	
	const [dateWindow, setDateWindow] = useState(query || today)
	const [manualSelectedDate, setManualSelectedDate] = useState(null)
	const [calMobile, setCalMobile] = useState(false)
console.log(query);

	const handleDateChange = (date) => {
		setManualSelectedDate(date)
		findEventsFromDate(date, date)
	}

	const getFormatedDate = (date) => {
		const [day, month, year] = date.split('-')
		return `${year}-${month}-${day}`
	}

	const findClosestFutureDateWithEvents = (currentDate) => {
		const futureEvents = events.filter(news => isAfter(new Date(news.event.start_date), currentDate))
		futureEvents.sort((a, b) => new Date(a.event.start_date) - new Date(b.event.start_date))
		if (futureEvents.length > 0) {
			return new Date(futureEvents[0].event.start_date)
		}
		return currentDate
	}
	const findEventsFromDate = (currentDate, closestDate) => {				
		if (events) {
			const actualEvents = events.filter(news => 				
				format(new Date(news.event.start_date), 'd MMMM', { locale: locales[language] }) === format(currentDate, 'd MMMM', { locale: locales[language] })				
			)		
			setDateWindow(currentDate)
			if (!actualEvents.length) {
				const closestDateWithEvents = findClosestFutureDateWithEvents(closestDate)
				setSelectedDate(closestDateWithEvents)
				setIstoday(false)
			} else {
				setSelectedDate(currentDate)
				setIstoday(true)
			}
		}
	}
	useEffect(() => {
		const dateToUse = manualSelectedDate ?? query ?? today		
		setSelectedDate(dateToUse)
		findEventsFromDate(dateToUse, dateToUse)		
	}, [language, events])
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })	
	}, [])
	
	const openMobileCalendar = (isOpen) => {
		window.scrollTo({ top: 0 })
		setCalMobile(isOpen)
	}
	return (
		<div>
			<div className={s.high_title}>
				{t('date')}
			</div>
			<div className={s.wrapper}>
				<NavigationBar
					mainPage={t('mainLink')}
					secondLink={'/history'}
					secondLevel={t('history')}
					thirdLevel={t('date')} />
				<div className={s.date_container}>
					<div className={s.date}>
						<div onClick={() => openMobileCalendar(true)} className={s.cal_mobile_button}><CalIcon /></div>
						<span>{format(dateWindow, 'd MMMM', { locale: locales[language] })}</span>
					</div>
					<div className={s.which_day}>
						{isToday
							? t('nowDay')
							: t('nextDay')}</div>
				</div>
				<div className={s.content}>
					{status === 'loading' 
						? <div className={`${s.news_container} ${s.test}`}>
							<Loading />
						</div>
						: <div className={s.news_container}>
						{events?.map((news, index) => (
							
							format(news.event.start_date, 'd MMMM', { locale: locales[language] }) === format(selectedDate, 'd MMMM', { locale: locales[language] }) ?
								<NewsItem
									key={index}
									start_date={format(getFormatedDate(news.name), 'd MMMM yyyy', { locale: locales[language] })}
									text={news.about} />
								: null
						))}
					</div>}

					<div className={s.calendar}>
						<div className={s.cal_container}>
							<Calendar language={locales[language]} onDateChange={handleDateChange} />
						</div>
					</div>
				</div>
			</div>
			{calMobile &&
				<div className={s.calendar_mobile}>
					<div className={s.cal_container_mobile}>
						<div className={s.cal_panel}>
							<div>{t('selectDate')}</div>
							<Cross onClick={() => openMobileCalendar(false)}/>
						</div>
						<Calendar language={locales[language]} onDateChange={handleDateChange} />
					</div>
				</div>
			}
		</div>
	)
}