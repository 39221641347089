import React, { useState } from 'react'
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, addMonths, subMonths, isSameMonth, isSameDay } from 'date-fns'
import './Calendar.scss'
import { ReactComponent as Arrow } from '../../assets/vector.svg'

export const Calendar = ({ language, onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const today = new Date()

  const renderHeader = () => {
    const dateFormat = 'LLLL'

    return (
      <div className="header row flex-middle">
        <div className="">
          <span>{format(currentDate, dateFormat, { locale: language })}</span>
        </div>
        <div className="arrows">
          <div className="icon" onClick={prevMonth}>
            <Arrow />
          </div>
          <div onClick={() => nextMonth()} className="icon right">
            <Arrow />
          </div>
        </div>
      </div>
    )
  }

  const renderDays = () => {
    const dateFormat = 'EEEEEE'
    const days = []

    let startDate = startOfWeek(currentDate, { locale: language })

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat, { locale: language })}
        </div>
      )
    }

    return <div className="days row">{days}</div>
  }

  const renderCells = () => {
    const monthStart = startOfMonth(currentDate)
    const monthEnd = endOfMonth(monthStart)
    const startDate = startOfWeek(monthStart, { locale: language })
    const endDate = endOfWeek(monthEnd, { locale: language })

    const dateFormat = 'd'
    const rows = []

    let days = []
    let day = startDate
    let formattedDate = ''

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat)
        const cloneDay = day

        const isToday = isSameDay(day, today)

        days.push(
          <div
            className={`col cell ${!isSameMonth(day, monthStart) ? 'disabled' : isSameDay(day, currentDate) ? 'selected' : isToday ? 'today' :  ''}`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
          </div>
        )
        day = addDays(day, 1)
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      )
      days = []
    }
    return <div className="body">{rows}</div>
  }

  const onDateClick = (day) => {
    setCurrentDate(day)
    onDateChange(day)
  }

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1))
  }

  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1))
  }

  return (
    <div className='calendar_cont'>
      <div className="calendar">
        {renderHeader()}
        {renderDays()}
        {renderCells()}
      </div>
    </div>
  )
}
