import s from './Places.module.scss'
import { ReactComponent as Search } from '../../assets/search.svg'
import { useState } from 'react'
import { MapBox } from '../../components/MapBox/MapBox'
import { CardItem } from '../../components/CardItem/CardItem'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ButtonSwitcher } from '../../components/ButtonSwitcher/ButtonSwither'
import { NavigationBar } from '../../components/NavigationBar/NavigationBar'
import { stringToNumber } from '../../helper'


export const Places = () => {
    const [buttonActive, setButtonActive] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const { places } = useSelector(state => state.places)
    const { t } = useTranslation()

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }
    const filteredPlaces = places?.filter(place =>
        place.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    window.scrollTo({ top: 0 })
    return (
        <div>
            <div className={s.high_title}>
                {t('places')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar
                    mainPage={t('mainLink')}
                    secondLevel={t('history')}
                    secondLink={'/history'}
                    thirdLevel={t('places')} />
                <div className={s.setting_line}>
                    <div className={s.filter_search_container}>
                        <div className={s.search}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder={t('placesPlaceholder')}
                            />
                            <Search />
                        </div>
                    </div>
                    <ButtonSwitcher buttonActive={buttonActive} setButtonActive={setButtonActive} />
                </div>
                {!buttonActive && <MapBox points={filteredPlaces} />}
                <div style={buttonActive ? { display: 'flex' } : { display: 'none' }}
                    className={s.organizations_container}>
                    {filteredPlaces?.map((place, index) => (
                        <CardItem
                            key={index}
                            link={`history/places/${stringToNumber(place.name)}`}
                            image={place.place.logo}
                            text={place.name} />
                    ))}
                </div>
            </div>
        </div>
    )
}