import { useTranslation } from 'react-i18next'
import s from './Footer.module.scss'

export const Footer = () => {
    const { t } = useTranslation()
    return (
        <div className={s.footer_comtainer}>
            <div className={s.blocks}>
                <div className={s.left_block}>
                    <div>
                        {t('address')}
                    </div>
                    <a href="mailto:nasb@presidium.bas-net.by">nasb@presidium.bas-net.by</a>
                    <a target='_blank' href="https://nasb.gov.by">nasb.gov.by</a>
                </div>
                <div className={s.right_block}>
                    <div>
                        {t('developer')}:
                        <a href="https://ssrlab.by/"
                            rel='noreferrer'
                            target='_blank'
                        >ssrlab.by</a>
                    </div>
                </div>
            </div>
            <div className={s.end_block}>
                {t('underText')}
            </div>
        </div>
    )
}