import { Link } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../assets/vector.svg'
import s from './LinkBox.module.scss'

export const LinkBox = ({ title, path }) => {
    return (
        <Link to={path} className={s.link}>
            <div >
                {title}
                <Arrow />
            </div>
        </Link>
    )
}