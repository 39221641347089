import s from './Organizations.module.scss'
import { ReactComponent as Filter } from '../../assets/filter.svg'
import { ReactComponent as Cross } from '../../assets/cross.svg'
import { ReactComponent as Search } from '../../assets/search.svg'
import { useEffect, useRef, useState } from 'react'
import { MapBox } from '../../components/MapBox/MapBox'
import { CardItem } from '../../components/CardItem/CardItem'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ButtonSwitcher } from '../../components/ButtonSwitcher/ButtonSwither'
import { FilterBox } from '../../components/FilterBox/FilterBox'
import { NavigationBar } from '../../components/NavigationBar/NavigationBar'
import { stringToNumber } from '../../helper'

export const Organizations = () => {
    const { organizations, language } = useSelector(state => state.organizations)
    const [buttonActive, setButtonActive] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [isFilter, setIsFilter] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [filteredOrganizations, setFilteredOrganizations] = useState(organizations)
    const [visibleCount, setVisibleCount] = useState(15)

    const { t } = useTranslation()
    const filterRef = useRef(null)

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }

    const handleCategoryChange = (category) => {
        setSelectedCategories(prevSelected =>
            prevSelected.includes(category)
                ? prevSelected.filter(c => c !== category)
                : [...prevSelected, category]
        )
    }

    useEffect(() => {
        let filtered = organizations

        if (searchTerm) {
            filtered = filtered.filter(org =>
                org.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }

        if (selectedCategories.length > 0) {
            filtered = filtered.filter(org =>
                selectedCategories.includes(org.organization.department_filter_translations[language - 1].name)
            )
        }

        setFilteredOrganizations(filtered)
    }, [searchTerm, selectedCategories, organizations])

    const showMoreOrganizations = () => {
        setVisibleCount(prevCount => prevCount + 15)
    }

    const getCategoryCounts = (orgs) => {
        const categoryCounts = {}
        orgs?.forEach(org => {
            const categoryName = org.organization.department_filter_translations[language - 1].name
            if (categoryCounts[categoryName]) {
                categoryCounts[categoryName]++
            } else {
                categoryCounts[categoryName] = 1
            }
        })

        return Object.keys(categoryCounts).map(categoryName => ({
            name: categoryName,
            count: categoryCounts[categoryName]
        }))
    }
    const categories = getCategoryCounts(organizations)

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsFilter(false)
            }
        }

        if (isFilter) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isFilter])

    return (
        <div>
            <div className={s.high_title}>
                {t('organization')}
            </div>
            <div className={s.wrapper}>
                <NavigationBar mainPage={t('mainLink')} secondLevel={t('organization')} />
                <div className={s.setting_line}>
                    <div className={s.filter_search_container}>
                        <div ref={filterRef} className={s.filter_container}>
                            <div onClick={() => setIsFilter(!isFilter)} className={`${s.filter} ${isFilter ? s.active : ''}`}>
                                {isFilter ? <Cross /> : <Filter />}
                            </div>
                            {isFilter &&
                                <FilterBox
                                    categories={categories}
                                    selectedCategories={selectedCategories}
                                    handleCategoryChange={handleCategoryChange}
                                />
                            }
                        </div>
                        <div className={s.search}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder={t('orgPlaceholder')}
                            />
                            <Search />
                        </div>
                    </div>
                    <ButtonSwitcher buttonActive={buttonActive} setButtonActive={setButtonActive} />
                </div>
                {!buttonActive && <MapBox points={filteredOrganizations} />}
                <div style={buttonActive ? { display: 'flex' } : { display: 'none' }} className={s.organizations_container}>
                    {filteredOrganizations
                        ?.slice(0, visibleCount)
                        .map((org, index) => (
                            <CardItem
                                key={index}
                                link={`organizations/${stringToNumber(org.name)}`}
                                image={org.organization.logo}
                                text={org.name}
                            />
                        ))}
                </div>
                {visibleCount < filteredOrganizations?.length && (
                    <div className={s.show_more_button}>
                        <span onClick={showMoreOrganizations}>{t('showMore')}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

