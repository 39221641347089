import { forwardRef } from 'react'
import s from './FilterBox.module.scss'

export const FilterBox = forwardRef(({ categories, selectedCategories, handleCategoryChange }, ref) => {
    return (

        <div className={s.filterbox}>
            {categories?.map((cat, index) => (
                <div className={s.checkbox} key={index}>
                    <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        checked={selectedCategories.includes(cat.name)}
                        onChange={() => handleCategoryChange(cat.name)}
                    />
                      <label className={s.label} htmlFor={`checkbox-${index}`}>{cat.name} ({cat.count})</label>
                </div>
            ))}
        </div>
    )
})